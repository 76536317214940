<template>
  <div class="body">
    <!-- 陪护人员核酸检测 -->
    <van-cell
      title="切换就诊人"
      icon="manager-o"
      is-link
      style="text-align: left"
      @click="openChooise()"
    >
      <template #default>
        <span style="color: #999">{{ chooseItem.name }}</span>
      </template>
    </van-cell>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-choose-jzk-over">
        <div class="yyqr-choose-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-choose-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooseJZPerson(item)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}</span
        >
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-choose-nodata" v-show="list.length == 0">
        <img
          src="@/assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-choose-add" @click="addPatient">
        <van-icon name="plus" />添加就诊人
      </div>
    </van-popup>
    <div class="checked-info-item-radio">
      <van-radio-group
        v-model="collectType"
        class="radioGroup"
        direction="horizontal"
      >
        <van-radio :name="1" icon-size="18px" checked-color="#00D48A">
          <!-- 2 -->
          <template #icon="props">
            <i :class="props.checked ? 'activeIcon' : 'inactiveIcon'"></i>
          </template>
          <b style="color: #990000">标本单采</b>
        </van-radio>
      </van-radio-group>
    </div>
    <!-- <div class="promise-item">
      <van-cell-group>
        <van-cell>
          <van-field
            v-model="inpNo"
            label="患者住院号"
            label-width="2rem"
            input-align="right"
            placeholder="请填写患者住院号"
          />
        </van-cell>
      </van-cell-group>
    </div> -->
    <van-form @submit="getNucleicJudgeNext">
      <div class="promise-item">
        <van-cell-group>
          <van-cell>
            <van-field
              v-model="submitForm.name"
              label="姓名"
              label-width="2rem"
              input-align="right"
              required
              placeholder="请填写姓名"
            />
          </van-cell>
          <van-cell>
            <van-field
              ref="idNumber"
              v-model="submitForm.idNumber"
              label="身份证号"
              label-width="2rem"
              input-align="right"
              required
              placeholder="请填写身份证号"
            />
          </van-cell>
          <van-cell>
            <van-field
              v-model="submitForm.phone1"
              label="联系电话"
              label-width="2rem"
              input-align="right"
              required
              placeholder="请填写手机号"
            />
          </van-cell>
          <van-cell>
            <van-field
              v-model="submitForm.address"
              label="地址"
              label-width="2rem"
              input-align="right"
              placeholder="请填写地址"
            >
            </van-field>
          </van-cell>
        </van-cell-group>
      </div>
      <div class="nextStep">
        <van-button
          block
          type="info"
          :loading="btnLoading"
          class="submit-btn"
          native-type="submit"
          >下一步</van-button
        >
      </div>
    </van-form>
    <van-dialog
      class="dialog"
      v-model="overflowShow1"
      title="温馨提示"
      :show-cancel-button="false"
      :show-confirm-button="false"
      :overlayStyle="{ backgroundColor: 'rgba(0,0,0,0.1)' }"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <p>暂无就诊人信息，请添加就诊人</p>
      <van-button class="cancle-btn" @click="overflowShow1 = false"
        >关闭</van-button
      >
      <van-button type="primary" class="confirm-btn" @click="addPatient"
        >确认</van-button
      >
    </van-dialog>
    <van-dialog
      class="dialog"
      v-model="overflowShow"
      title="温馨提示"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <p>
        {{ content }}
      </p>
      <van-button type="primary" class="confirm-btn" to="/treatmentServices"
        >确认</van-button
      >
    </van-dialog>
    <van-dialog
      v-model="showDialog"
      :show-cancel-button="false"
      :show-confirm-button="false"
      @confirm="handleConfirm"
    >
      <template #title>
        <h3>提示</h3>
      </template>
      <template #default>
        <p class="dialog-message">{{ tip_notice }}</p>
        <van-button class="cancle-btn" @click="showDialog = false"
          >关闭</van-button
        >
        <van-button type="primary" class="confirm-btn1" @click="handleConfirm"
          >确认</van-button
        >
      </template>
    </van-dialog>
  </div>
</template>

<script>
import { Toast, Notify } from "vant";
export default {
  name: "feverClinic",
  data() {
    return {
      showPopup: false, // 弹出层
      personId: "",
      chooseItem: "",
      list: [],
      collectType: 1,
      submitForm: {
        name: "",
        idNumber: "",
        sex: "",
        age: "",
        phone1: "",
        address: "",
        company: "",
        job: "",
      },
      overflowShow1: false,
      overflowShow: false,
      content: "",
      healthId: "",
      showDialog: false,
      tip_notice: "",
      inpNo: "",
      btnLoading: false,
    };
  },
  created() {
    // 骨架屏的状态 生产环境为true。测试为false
    this.getAliPayCode();
  },
  methods: {
    /**
     * 点击对话框确定按钮
     */
    handleConfirm() {
      this.showDialog = false;
      this.$router.push("/nucleicPayment");
    },
    /**
     * 获取微信公众平台的code
     */
    getAliPayCode() {
      // this.$store.commit("showLoading");
      // 判断是否有微信code
      let sessionCode = sessionStorage.getItem("AliPayCode");
      if (!sessionCode) {
        // 没有则进行授权
        const redirectUrl = `${domain}index.html%23%2FaccompanyingDetection`;
        this.code = this.getUrlCode().code; // 截取auth_code用于获取openid
        if (this.code == null || this.code === "" || this.code == undefined) {
          // 如果没有code，则去请求
          window.location.replace(
            `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.APPID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`
          );
        } else {
          sessionStorage.setItem("AliPayCode", this.code);
          // 获取openid
          this.getOpenID(this.code);
        }
      } else {
        this.getOpenID(sessionCode);
      }
    },
    /**
     * 根据code获取openid
     */
    getOpenID(code) {
      // 判断是否有openid
      if (sessionStorage.getItem("openid")) {
        this.getList();
        return;
      }
      let postData = {
        code: code,
      };
      this.$http
        .post("/api/login/wx", postData)
        .then((res) => {
          sessionStorage.setItem("user", JSON.stringify(res.data));
          sessionStorage.setItem("userid", res.data.id);
          sessionStorage.setItem("openid", res.data.userId);
          this.getList();
          this.$store.commit("hideLoading");
        })
        .catch((err) => {
          this.$store.commit("hideLoading");
          this.$toast.fail("发生错误，请关闭页面之后重新授权...");
          console.log(err);
          // 发生错误说明可能是code过期，或者session丢失，重置session重新鉴权
          sessionStorage.setItem("AliPayCode", "");
          sessionStorage.setItem("userid", "");
          sessionStorage.setItem("openid", "");
          // location.reload();
        });
    },
    // 获取url中code
    getUrlCode() {
      let url = location.href;
      var s = url.indexOf("?");
      var t = url.substring(s + 1);
      let strs = t.split("&");
      let theRequest = new Object();
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
      return theRequest;
    },
    /**
     * 选择就诊人
     */
    chooseJZPerson(item) {
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
      this.chooseItem = item;
      this.personId = item.id;
      this.showPopup = false;
      this.submitForm.name = item.name;
      this.submitForm.idNumber = item.idNumber;
      this.submitForm.phone1 = item.phone;
      this.submitForm.address = item.adress;
      this.submitForm.job = item.job;
      this.submitForm.company = item.company;
      this.healthId = item.healthId;
    },
    /**
     * 点击就诊人
     */
    openChooise() {
      this.showPopup = true;
    },
    /**
     * 添加就诊人
     */
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },
    /**
     * 判断是否有就诊人
     */
    isHasPatient() {
      if (!this.chooseItem) {
        this.overflowShow1 = true;
        return false;
      }
      return true;
    },
    /**
     * 获取就诊人信息的方法
     */
    getList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status === 200) {
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooseItem = this.list[i];
                  }
                }
                if (!this.chooseItem.deafult) {
                  this.chooseItem.deafult = true;
                  this.list[0].deafult = true;
                }
                sessionStorage.setItem(
                  "CurrentJZPerson",
                  JSON.stringify(this.chooseItem)
                );
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooseItem = CurrentJZPerson;
            }
          }
          this.healthId = this.chooseItem.healthId;
          this.getState();
        })
        .then(() => {
          this.submitForm.name = this.chooseItem.name;
          this.submitForm.idNumber = this.chooseItem.idNumber;
          this.submitForm.phone1 = this.chooseItem.phone;
          this.submitForm.address = this.chooseItem.adress;
          this.submitForm.job = this.chooseItem.job;
          this.submitForm.company = this.chooseItem.company;
          this.getNucleicJudge();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * 下一步操作
     */
    onSubmit() {
      // 判断是否有就诊人
      if (!this.isHasPatient()) {
        return;
      }
      // 表单非空判断
      let { name, age, sex, idNumber, phone1, address, company, job } =
        this.submitForm;
      if (name == "") {
        Notify({ type: "warning", message: "请填写姓名！" });
        return;
      }
      if (idNumber == "") {
        Notify({ type: "warning", message: "请填写身份证号！" });
        return;
      }

      let idNumberReg =
        /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      if (!idNumberReg.test(idNumber)) {
        idNumber = "";
        this.$toast.fail("身份证号码有误");
        this.$refs.idNumber.focus();
        return;
      }
      if (phone1 == "") {
        Notify({ type: "warning", message: "请填写手机号！" });
        return;
      }
      // if (this.inpNo === "") {
      //   Notify({ type: "warning", message: "请填写患者住院号！" });
      //   return;
      // }
      // 创建陪护人员核酸检测订单
      let postData = {
        userid: sessionStorage.getItem("openid"),
        name: name,
        idNumber: idNumber,
        phone: phone1,
        address: address,
        healthId: this.healthId,
        inpNo: this.inpNo,
      };
      this.btnLoading = true;
      this.$http
        .post("/api/nucleic/ph", postData)
        .then((result) => {
          this.btnLoading = false;
          this.$router.push({
            path: "/wxPayPage",
            query: {
              id: result.data.order_id,
              price: result.data.total * 100,
              collectType: 1,
              type: 0,
              itemName: result.data.list[0].item_name,
              success: true, // 是否跳转到支付成功的页面
            },
          });
        })
        .catch((err) => {
          this.btnLoading = false;
          Toast.fail({ message: err.response.data });
        });
    },
    /**
     * 获取核酸检测功能开放状态
     */
    getState() {
      // personType 1 陪护核酸检测
      this.$http.get("/api/search/state", { type: 1 }).then((result) => {
        this.overflowShow = !result.data.state;
        if (result.data.state) {
          this.isHasPatient();
        }
        this.content = result.data.content;
        return;
      });
    },
    /**
     * 判断某个日期距离今天有多少天
     */
    daysDistance(date1) {
      //parse() 是 Date 的一个静态方法 , 所以应该使用 Date.parse() 来调用，而不是作为 Date 的实例方法。返回该日期距离 1970/1/1 午夜时间的毫秒数
      date1 = Date.parse(date1);
      //计算两个日期之间相差的毫秒数
      var ms = new Date() - date1;
      //如果ms小于0，则让它等于0
      if (ms < 0) ms = 0;
      //毫秒数除以一天的毫秒数,就得到了天数
      var days = Math.floor(ms / (24 * 3600 * 1000));
      if (days >= 365) {
        return ~~(days / 365) + " 岁";
      } else if (days < 30) {
        return days + " 天";
      } else {
        return ~~(days / 30) + " 月";
      }
    },
    /**
     * 获取当前用户是否有预约信息
     */
    getNucleicJudge() {
      let param = {
        name: this.submitForm.name,
        idNumber: this.submitForm.idNumber,
        collectType: 1,
        openid: sessionStorage.getItem("openid"),
      };
      this.$http.get("/api/nucleic/judge", param).then((result) => {
        if (result.status != 204) {
          if (result.data.changeStatus == 0 || result.data.changeStatus == 8) {
            // 已提交未支付
            this.showDialog = true;
            this.tip_notice =
              "您当天已预约河南省第二人民医院本部核酸检测，请到“诊疗服务-核酸缴费（未缴费）”完成支付！";
          } else if (result.data.changeStatus == 1) {
            // 已提交已支付
            this.showDialog = true;
            this.tip_notice =
              "您当天已预约河南省第二人民医院本部核酸检测，可到“诊疗服务-核酸缴费（已缴费）”查看！";
          }
        }
      });
    },
    /**
     * 点击下一步判断是否有预约状态
     */
    getNucleicJudgeNext() {
      let param = {
        name: this.submitForm.name,
        idNumber: this.submitForm.idNumber,
        collectType: this.collectType,
        openid: sessionStorage.getItem("openid"),
      };
      this.$http.get("/api/nucleic/judge", param).then((result) => {
        if (result.status == 204) {
          this.onSubmit();
        } else {
          if (result.data.changeStatus == 0 || result.data.changeStatus == 8) {
            // 已提交未支付
            this.showDialog = true;
            this.tip_notice =
              "您当天已预约河南省第二人民医院本部核酸检测，请到“诊疗服务-核酸缴费（未缴费）”完成支付！";
          } else if (result.data.changeStatus == 1) {
            // 已提交已支付
            this.showDialog = true;
            this.tip_notice =
              "您当天已预约河南省第二人民医院本部核酸检测，可到“诊疗服务-核酸缴费（已缴费）”查看！";
          }
        }
      });
    },
  },
  watch: {
    "submitForm.idNumber": function (newValue, oldValue) {
      if (newValue) {
        if (newValue.length == 18) {
          newValue.slice(16, 17) % 2 === 0
            ? (this.submitForm.sex = "女")
            : (this.submitForm.sex = "男");
          let birthday =
            newValue.slice(6, 10) +
            "-" +
            newValue.slice(10, 12) +
            "-" +
            newValue.slice(12, 14);
          this.submitForm.age = this.daysDistance(birthday);
        } else {
          this.submitForm.sex = "";
          this.submitForm.age = "";
        }
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped>
.body {
  background-color: #f8f9f9;
  margin-bottom: 64px;
}
.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}
.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}
.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}
.yyqr-choose-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}
/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}
.checked-info-item-radio {
  width: 10rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #fff;
  height: 80px;
  line-height: 80px;
  margin: auto;
  font-size: 0.4rem;
  /* background-color: pink; */
  /*border: 1px solid #D3D3D6;*/
  /*border-radius: 5px;*/
}

.checked-info-item-radio .van-radio {
  margin: 0.2rem 0.3rem;
}
.radioGroup {
  height: 80px;
  font-size: 0.42rem !important;
}

.activeIcon {
  display: inline-block;
  height: 15px;
  width: 15px;
  border: 1px solid #03d58e;
  border-radius: 50%;
  vertical-align: top;
}
.activeIcon:after {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #03d58e;
  position: relative;
  left: 1px;
  top: 1px;
}
.inactiveIcon {
  display: inline-block;
  height: 15px;
  width: 15px;
  border: 1px solid #a8a5a6;
  border-radius: 50%;
}
.nucle-tips {
  font-size: 0.3rem;
  color: #990000;
  margin-left: 10px;
}
.nextStep {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  width: 100vw;
}
.submit-btn {
  width: 9rem;
  margin: 10px auto;
  border-radius: 5px;
  border: none;
  background-image: linear-gradient(to right, #57fdc4, #00d48b);
}
.promise-item {
  margin: auto;
  font-size: 0.4rem;
  text-align: left;
  border: 1px solid #ffffffff;
  border-radius: 10px;
  background-color: #ffffffff;
  padding-left: 10px;
}
.promise-item .van-cell {
  padding: 5px 16px;
}
.dialog {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 0.8rem;
  box-sizing: border-box;
}

.dialog div {
  text-align: left;
}

.dialog p {
  text-indent: 0.8rem;
  text-align: left;
}

.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-top: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}
.confirm-btn1 {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}
.cancle-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}
.dialog-title {
  margin-bottom: 0.3rem;
}
.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}

.yyqr-choose-jzk .checked {
  color: #03d28c;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: 2px;
  left: -2px;
}

.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}
.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: -2px;
  left: -2px;
}
/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}

/deep/ .van-field__error-message {
  text-align: right;
  color: #cbc9d6;
}

.dialog-message {
  font-size: 0.45rem;
  line-height: 26px;
  margin-bottom: 5px;
  padding: 0.5rem 0.8rem;
}
</style>
